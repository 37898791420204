import { HttpRequest } from '@angular/common/http'
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular'

const acceptablePaths = ['/assets']

export function keycloakInitializer(keycloak: KeycloakService) {
  return async () => {
    keycloak.keycloakEvents$.subscribe({
      next: (e: KeycloakEvent) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          keycloak
            .updateToken(0)
            .then((res) => {
              console.debug(`app: token refresh: ${res}`)
            })
            .catch(function () {
              window.location.reload()
            })
        }
      },
    })

    const isAppMobil = /Mobi|Android/i.test(navigator.userAgent)
    let initOptions: any = {
      flow: 'hybrid',
      adapter: isAppMobil ? 'cordova' : 'default',
      onLoad: isAppMobil ? 'login-required' : 'check-sso',
      checkLoginIframe: !isAppMobil,
      enableLogging: true,
    }

    if (isAppMobil) {
      initOptions.silentCheckSsoRedirectUri = window.location.origin + '/assets/silent-check-sso.html'
    }

    return await keycloak.init({
      config: await (await fetch('/assets/keycloak.json')).json(),
      initOptions: initOptions,
      bearerExcludedUrls: ['/assets'],
      loadUserProfileAtStartUp: true,
      shouldAddToken: ({ method, url }: HttpRequest<unknown>) => {
        const isGetRequest = 'GET' === method.toUpperCase()
        const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path))
        return !(isGetRequest && isAcceptablePathMatch)
      },
    })
  }
}
